@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}


  /* width */
::-webkit-scrollbar {
  height: 5px;
}


/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

